export enum ModalType {
  Listing = 'listing',
}

// This map clears specific query params when a modal closes.
// If no params are defined for a modal, all query params will be removed.
// Use this map to preserve specific query params when closing a modal.
// P.S it may not be necessary for your modal
export const paramsMap: Record<ModalType, string[]> = {
  [ModalType.Listing]: ['slug', 'type'],
}
