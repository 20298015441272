import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { ReactNode, type ReactElement } from 'react'
import { ThemeProvider } from 'styled-components'

import { PageCommonFragment } from '@graph/fragments/PageCommon.generated'
import UnkownRenderError from '@ui/errors/UnkownError'
import useModal from '@ui/modal/useModal'
import { SnackbarProvider } from '@ui/snackbar/SnackbarContext'
import theme from '@ui/theme'
import GlobalStyle from '@ui/theme/GlobalStyle'
import ComingSoon from 'app/ComingSoon/ComingSoon'
import { CONFIRM_ACCOUNT_MODAL_ID } from 'app/constants'
import ExperimentsProvider from 'app/ExperimentsProvider'
import { FeatureFlagsProvider } from 'app/FeatureFlagContext/FeatureFlagContext'
import useAppInitialize from 'app/useAppInitialize'
import { getOrganizationStructuredData } from 'app/utils'
import { Locale } from 'locale/constants'
import { LocaleContextProvider } from 'locale/LocaleContext'
import { ViewerGlobalProvider } from 'viewer/ViewerGlobalContext'

const CountrySelectPanel = dynamic(() => import('app/CountrySelectPanel'), {
  ssr: false,
})

const ConfirmPasswordModal = dynamic(
  () => import('@ui/modals/ConfirmPasswordModal/ConfirmPasswordModal'),
  {
    ssr: false,
  }
)

/**
 * These are props that are derived from cookies server side
 */
export type GlobalPagePropsFromCookies = {
  localeFromServerCookie: Locale | null
  autoFillPromoCode: string | null
}

/**
 * AppPageProps are the props that are are added to pageProps in getServerSideProps
 * by addGlobalPageProps decorator.
 */
export type AppPageProps = {
  includeCountrySelectPanel?: boolean
  __APOLLO_STATE__: any
} & GlobalPagePropsFromCookies

/**
 * ServerSideProps are the props that are passed to the page component from the server.
 * Each page's getServerSideProps call decorates the props with additional items that
 * are not needed by the page component which is why we omit them.
 */
type ServerSideProps<P> = Omit<
  P & AppPageProps,
  'localeFromServerCookie' | '__APOLLO_STATE__' | 'includeCountrySelectPanel'
>

export type NLPage<Props = {}, LayoutProps = {}> = NextPage<ServerSideProps<Props>> & {
  getLayout?: (page: ReactElement, pageProps: ServerSideProps<Props> & LayoutProps) => ReactNode
}

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NLPage<ServerSideProps<P>, {}>
}

const App = <P extends {}>({
  Component,
  pageProps,
}: AppPropsWithLayout<P & AppPageProps & PageCommonFragment>) => {
  const {
    __APOLLO_STATE__,
    localeFromServerCookie,
    includeCountrySelectPanel,
    ...serverSideProps
  } = pageProps

  const organizationStructuredData = getOrganizationStructuredData()
  const viewer = serverSideProps?.viewer
  const { apolloClient } = useAppInitialize(__APOLLO_STATE__, viewer)
  const getLayout = Component.getLayout || ((page) => page)
  const { isVisible, closeModal } = useModal(CONFIRM_ACCOUNT_MODAL_ID)

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Head>
          <title>Nanny Lane</title>
          <meta
            name='viewport'
            content={[
              'width=device-width',
              'height=device-height',
              'initial-scale=1',
              'maximum-scale=1',
              'minimum-scale=1',
              'user-scalable=0',
            ].join(',')}
          />
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{ __html: organizationStructuredData }}
          />
        </Head>
        <Sentry.ErrorBoundary fallback={<UnkownRenderError />}>
          <FeatureFlagsProvider flags={serverSideProps.featureFlags}>
            <ViewerGlobalProvider viewerFromServer={viewer}>
              <ExperimentsProvider>
                <LocaleContextProvider localeFromServerCookie={localeFromServerCookie}>
                  <SnackbarProvider>
                    {getLayout(<Component {...serverSideProps} />, pageProps)}

                    {includeCountrySelectPanel && <CountrySelectPanel />}

                    {/*
                      Modal is always mounted but lazy loaded to avoid bundle bloat 
                      while maintaining fast open times
                    */}
                    <ConfirmPasswordModal isVisible={isVisible} onClose={closeModal} />
                    <ComingSoon />
                  </SnackbarProvider>
                </LocaleContextProvider>
              </ExperimentsProvider>
            </ViewerGlobalProvider>
          </FeatureFlagsProvider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
