import css from '@styled-system/css'
import { ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { variant } from 'styled-system'

import { ContentBlock } from '@ui/layout/ContentBlock'
import { respondTo } from '@ui/theme'

export type ModalVariant = 'normal' | 'bubble'

type ModalProps = {
  children: ReactNode
  onClose: () => void
  variant?: ModalVariant
  options?: {
    fullViewport?: boolean
    closeOnClickOutside?: boolean
  }
}

const defaultOptions = {
  fullViewport: true,
  closeOnClickOutside: true,
}

const Modal = ({ children, onClose, variant = 'normal', options = defaultOptions }: ModalProps) => {
  const { fullViewport, closeOnClickOutside } = { ...defaultOptions, ...options }
  const modalRef = useRef<HTMLDivElement>(null)
  const mouseDownTarget = useRef<EventTarget | null>(null)

  const setOuterPageBodyOverflow = (value: 'hidden' | 'auto') => {
    const scrollPosition = window.pageYOffset
    document.body.style.overflowY = value
    window.scrollTo(0, scrollPosition)
  }

  useEffect(() => {
    setOuterPageBodyOverflow('hidden')

    return () => {
      setOuterPageBodyOverflow('auto')
    }
  }, [])

  const onOverlayMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    mouseDownTarget.current = event.target
  }

  const onOverlayMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      closeOnClickOutside &&
      event.target === event.currentTarget &&
      mouseDownTarget.current === event.currentTarget
    ) {
      onClose()
    }
    mouseDownTarget.current = null
  }

  return (
    <ModalOverlay
      maxWidth='page.card.small'
      onMouseDown={onOverlayMouseDown}
      onMouseUp={onOverlayMouseUp}
      $variant={variant}
    >
      <ModalContainer ref={modalRef} $fullViewport={fullViewport} $variant={variant}>
        {children}
      </ModalContainer>
    </ModalOverlay>
  )
}

const overlayVariants = variant<any, ModalVariant>({
  prop: '$variant',
  variants: {
    normal: {
      px: 0,
    },
    bubble: {
      px: 4,
    },
  },
})

const ModalOverlay = styled(ContentBlock)<{ $variant: ModalVariant }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  ${css({
    bg: 'darkGrey75',
  })}

  ${overlayVariants}

  /* if modal is a child of Stack, the stack's margin will be applied to it */
  /* this fixes it */
  &&& {
    margin: 0;
  }
`

const containerVariants = variant<any, ModalVariant>({
  prop: '$variant',
  variants: {
    normal: {
      borderRadius: 0,
    },
    bubble: {
      borderRadius: '10px',
    },
  },
})

const ModalContainer = styled.div<{
  $fullViewport: boolean
  $variant: ModalVariant
}>`
  max-height: 100%;
  width: 100%;
  top: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${css({
    boxShadow: 'paper',
  })}

  position: ${(props) => (props.$fullViewport ? 'fixed' : 'relative')};
  height: ${(props) => (props.$fullViewport ? '100%' : 'auto')};

  ${containerVariants}

  ${respondTo.tablet`
    height: unset;
    position: relative;
    top: unset
  `};
`

export default Modal
