import css from '@styled-system/css'
import styled from 'styled-components'
import { color, ColorProps, fontWeight } from 'styled-system'

const propsToOmit = ['fontWeight']

const config = {
  shouldForwardProp: (prop: string) => !propsToOmit.includes(prop),
}

export const Highlight = styled.span.withConfig<{ fontWeight?: string } & ColorProps>(config)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;

  ${css({
    color: 'linkPurple',
  })}

  ${fontWeight}
  ${color}
`
