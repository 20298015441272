// @ts-nocheck
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'

import { isDevEnvironment } from 'app/utils'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  release: `nl-next@${process.env.NEXT_PUBLIC_REVISION}`,
  debug: false,
  sendDefaultPii: true,
  tracesSampleRate: 0,

  // Add browserTracingIntegration but DON'T set tracesSampleRate
  // See https://develop.sentry.dev/sdk/platform-specifics/javascript-sdks/browser-tracing/#tracing-without-performance
  integrations: [
    Sentry.browserTracingIntegration({
      tracingOrigins: [
        process.env.NEXT_PUBLIC_API_URI.replace('/graphql', '').replace('https://', ''),
      ],
    }),
  ],

  ignoreErrors: [
    "undefined is not an object (evaluating 'a.L')",
    "Can't find variable: _AutofillCallbackHandler",
    'window.googletag.pubads is not a function',
    "Can't find variable: gmo",
    'Iframe response timed out',
  ],

  beforeSend: (event) => {
    const breadcrumbs = event.breadcrumbs
    if (breadcrumbs && breadcrumbs.length > 0) {
      const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1]
      if (
        lastBreadcrumb?.category === 'fetch' &&
        lastBreadcrumb?.data?.url?.startsWith(
          'https://prod.tahoe-analytics.publishers.advertising.a2z.com'
        )
      ) {
        return null
      }
    }

    const stacktraceFrames = event.exception?.values[0]?.stacktrace?.frames
    if (stacktraceFrames?.length > 0) {
      const lastFrame = stacktraceFrames[stacktraceFrames.length - 1]
      if (
        lastFrame &&
        !!lastFrame.filename &&
        !(
          lastFrame.filename.startsWith('https://staging-cdn.nannylane.com') ||
          lastFrame.filename.startsWith('https://cdn.nannylane.com') ||
          lastFrame.filename.startsWith('./node_modules') ||
          lastFrame.filename.startsWith('app:///_next/')
        )
      ) {
        return null
      }
    }
    if (isDevEnvironment) return null
    return event
  },
})
