import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import styled, { css } from 'styled-components'

import Button from '@ui/button/Button'
import ButtonLink from '@ui/button/ButtonLink'
import PaperContentBlock from '@ui/common/PaperContentBlock'
import Icon from '@ui/icons/Icon'
import CrossCircledSvg from '@ui/icons/svgs/cross-circled.svg'
import { ContentBlock } from '@ui/layout/ContentBlock'
import Stack from '@ui/layout/stack'
import Modal from '@ui/modal/Modal'
import useModal from '@ui/modal/useModal'
import { Body } from '@ui/typography/Body'
import { Highlight } from '@ui/typography/Highlight'
import { COMING_SOON_MODAL_ID } from 'app/constants'
import { FEATURE_FLAGS } from 'app/FeatureFlagContext/constants'
import { useFeatureFlag } from 'app/FeatureFlagContext/FeatureFlagContext'
import { comingSoonPath, dashboardPath } from 'routes/static'
import { isViewerPaidRequiredCohort } from 'viewer/utils'
import { useViewerGlobalContext } from 'viewer/ViewerGlobalContext'

import megaphoneSvg from './images/megaphone.svg'

const ComingSoon = () => {
  const viewer = useViewerGlobalContext()
  const router = useRouter()
  const displayComingSoonActive = useFeatureFlag(FEATURE_FLAGS.displayComingSoon)
  const inPaidRequiredExperiment = isViewerPaidRequiredCohort(viewer)

  const {
    isVisible: isComingSoonModalVisible,
    closeModal: closeComingSoonModal,
    openModal: openComingSoonModal,
  } = useModal(COMING_SOON_MODAL_ID)

  const handleClose = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('hasSeenComingSoonModal', 'true')
    }
    closeComingSoonModal()
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hasSeenComingSoonModal = localStorage.getItem('hasSeenComingSoonModal') === 'true'
      const isOnComingSoonPage = router.pathname.includes(comingSoonPath)
      const isOnDashboard = router.pathname.includes(dashboardPath)

      if (
        displayComingSoonActive &&
        inPaidRequiredExperiment &&
        !isComingSoonModalVisible &&
        !hasSeenComingSoonModal &&
        !isOnComingSoonPage &&
        isOnDashboard
      ) {
        openComingSoonModal()
      }
    }
  }, [
    displayComingSoonActive,
    inPaidRequiredExperiment,
    isComingSoonModalVisible,
    openComingSoonModal,
    router.pathname,
  ])

  return (
    isComingSoonModalVisible && (
      <Modal
        onClose={handleClose}
        variant='bubble'
        options={{ fullViewport: false, closeOnClickOutside: false }}
      >
        <PaperContentBlock
          p={{ _: 4, tablet: 5 }}
          maxWidth={{ _: '100%', tablet: 'page.card.small' }}
          py='5'
        >
          <StyledButton
            type='button'
            onClick={handleClose}
            data-testid='modal-close-button'
            variant='unstyled'
            eventClickTarget='coming-soon-modal-dismissed'
          >
            <Icon src={CrossCircledSvg} />
          </StyledButton>

          <ContentBlock pt='4'>
            <Stack gap='5'>
              <Stack gap='4'>
                <ContentBlock px='25px'>
                  <Stack direction='horizontal' valign='center' gap={{ _: 4, tablet: 1 }}>
                    <Image src={megaphoneSvg} alt='Picture of a megaphone' />

                    <Stack gap='2' align='center'>
                      <Body fontWeight='medium'>Something new is</Body>

                      <Body fontWeight='medium' fontSize={{ _: 3, tablet: 4 }}>
                        COMING SOON
                      </Body>
                    </Stack>
                  </Stack>
                </ContentBlock>

                <PurpleBlock bg='purple.0' p='4'>
                  <Stack gap='4'>
                    <Body fontWeight='medium'>
                      We&apos;re slowly removing the free tier and introducing the{' '}
                      <Highlight fontWeight='bold' color='grey.3'>
                        Essential membership
                      </Highlight>
                      , ensuring all families have the tools needed to hire safely and successfully.
                    </Body>

                    <Body fontWeight='medium'>
                      <Highlight fontWeight='bold' color='grey.3'>
                        PLUS
                      </Highlight>{' '}
                      members can continue to enjoy their membership benefits.
                    </Body>
                  </Stack>
                </PurpleBlock>
              </Stack>

              <ButtonLink href={comingSoonPath}>Find out what&apos;s new!</ButtonLink>
            </Stack>
          </ContentBlock>
        </PaperContentBlock>
      </Modal>
    )
  )
}

const StyledButton = styled(Button)`
  position: absolute;
  top: 15px;
  left: 15px;
`

const PurpleBlock = styled(ContentBlock)`
  border-radius: 10px;
  ${css({
    boxShadow: 'button',
  })}
`

export default ComingSoon
