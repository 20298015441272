import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useCallback, useMemo } from 'react'

import { ModalType, paramsMap } from './constants'

const useModal = (modalId: string, flowId?: string) => {
  const router = useRouter()
  const { modal, group } = router.query

  const isVisible = useMemo(() => {
    const modalPresent = modal === modalId
    const groupPresent = group === flowId

    if (flowId) return modalPresent && groupPresent

    return modalPresent
  }, [flowId, group, modal, modalId])

  const openModal = useCallback(
    (urlParams?: { [key: string]: string }) => {
      router.push(
        {
          pathname: router.asPath.split('?')[0],
          query: {
            ...router.query,
            modal: modalId,
            ...(flowId ? { group: flowId } : {}),
            ...urlParams,
          },
        },
        undefined,
        { shallow: true }
      )
    },
    [modalId, flowId, router]
  )

  const closeModal = useCallback(() => {
    let query: ParsedUrlQuery = { ...router.query }

    const modalParams = paramsMap[modalId as ModalType]
    if (modalParams) {
      delete query.modal
      if (flowId) {
        delete query.group
      }

      modalParams.forEach((param) => {
        delete query[param]
      })
    } else {
      query = {}
    }

    router.push(
      {
        pathname: router.asPath.split('?')[0],
        query,
      },
      undefined,
      { shallow: true }
    )
  }, [router, flowId, modalId])

  return { isVisible, openModal, closeModal }
}

export default useModal
